import React, { useState, useEffect } from 'react';
import { User, Lock, Mail, Phone } from 'lucide-react';
import Header from '../components/HeaderComponent';

const LoginPage = () => {
  const [theme, setTheme] = useState('dark');
  const [isLogin, setIsLogin] = useState(true);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <div className={`
      min-h-screen flex items-center justify-center 
      ${theme === 'dark' 
        ? 'bg-zinc-900 text-zinc-100' 
        : 'bg-gray-50 text-gray-900'}
    `}>
      {/* Header */}
      <Header theme={theme} toggleTheme={toggleTheme} />

      <div className="w-full max-w-md px-4 pt-16">
        <div className={`
          ${theme === 'dark' ? 'bg-zinc-800 border-zinc-700' : 'bg-white border-gray-200'} 
          shadow-2xl rounded-xl border p-8
        `}>
        <h2 className={`
          text-center text-3xl font-bold 
          ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
        `}>
          Entrar
        </h2>
          {/* Formulário de Login */}
          {isLogin ? (
            <form>
              <div className="mb-4">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  E-mail
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="email" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="Seu e-mail"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  Senha
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="password" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="Sua senha"
                  />
                </div>
              </div>
              <button 
                type="submit"
                className={`
                  w-full py-2 rounded-md font-semibold
                  ${theme === 'dark' 
                    ? 'bg-violet-700 text-white hover:bg-violet-600' 
                    : 'bg-violet-600 text-white hover:bg-violet-700'}
                `}
              >
                Entrar
              </button>
              <div className="text-center mt-4">
                <a href="#" className={`
                  text-sm 
                  ${theme === 'dark' ? 'text-zinc-400 hover:text-zinc-200' : 'text-gray-500 hover:text-gray-700'}
                `}>
                  Esqueceu sua senha?
                </a>
              </div>
            </form>
          ) : (
            // Formulário de Cadastro
            <form>
              <div className="mb-4">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  Nome Completo
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="text" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="Seu nome completo"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  E-mail
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="email" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="Seu e-mail"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  Telefone
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="tel" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="(99) 99999-9999"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className={`
                  block mb-2 
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  Senha
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className={`
                      w-5 h-5 
                      ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-400'}
                    `} />
                  </div>
                  <input 
                    type="password" 
                    className={`
                      w-full p-2 pl-10 rounded-md 
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' 
                        : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}
                    `}
                    placeholder="Sua senha"
                  />
                </div>
              </div>
              <button 
                type="submit"
                className={`
                  w-full py-2 rounded-md font-semibold
                  ${theme === 'dark' 
                    ? 'bg-violet-700 text-white hover:bg-violet-600' 
                    : 'bg-violet-600 text-white hover:bg-violet-700'}
                `}
              >
                Cadastrar
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;