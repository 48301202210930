import React, { useState } from 'react';
import { Moon, Sun, Menu, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Header = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const handleNavigateLogin = () => {
    setIsMenuOpen(false);
    navigate('/login');
  };

  const handleNavigateSignUp = () => {
    setIsMenuOpen(false);
    navigate('/signup');
  };

  const navigateToHome = () => {
    setIsMenuOpen(false);
    navigate('/');
  };

  return (
    <header
      className={`${
        theme === 'dark' ? 'bg-zinc-800' : 'bg-white'
      } shadow-sm fixed w-full top-0 z-50`}
    >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
        {/* Logo com texto */}
        <button onClick={navigateToHome} className="flex items-center flex-shrink-0">
          <img
            src="/logo512.png"
            alt="Angel Model"
            className="w-16 h-16 mr-2"
          />
          <h1
            className={`text-2xl md:text-2xl font-poppins ${
             'text-violet-600'
            }`}
          >
            <span className="font-bold">angel</span>model
          </h1>
        </button>

        {/* Menu Desktop */}
        <div className="hidden md:flex items-center gap-6">
          <button
            onClick={toggleTheme}
            className={`
              p-2 rounded-full transition-all duration-300
              ${
                theme === 'dark'
                  ? 'bg-zinc-700 text-yellow-400 hover:bg-zinc-600'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }
            `}
          >
            {theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />}
          </button>
          <button
            onClick={handleNavigateLogin}
            className={`px-4 py-2 ${
              theme === 'dark'
                ? 'text-zinc-300 hover:text-white'
                : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            Entrar
          </button>
          <button
            onClick={handleNavigateSignUp}
            className={`px-4 py-2 rounded-md ${
              theme === 'dark'
                ? 'bg-violet-700 text-white hover:bg-violet-600'
                : 'bg-violet-600 text-white hover:bg-violet-700'
            }`}
          >
            Cadastrar
          </button>
        </div>

        {/* Menu Mobile */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleTheme}
            className={`
              p-2 rounded-full transition-all duration-300
              ${
                theme === 'dark'
                  ? 'bg-zinc-700 text-yellow-400 hover:bg-zinc-600'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }
            `}
          >
            {theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />}
          </button>
          <button onClick={toggleMenu} className="p-2">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </nav>

      {/* Menu Lateral */}
      <div
        className={`
          fixed top-0 right-0 h-full w-64 bg-white shadow-lg z-40 transform transition-transform duration-300
          ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          } ${theme === 'dark' ? 'bg-zinc-800 text-zinc-300' : 'bg-white text-gray-800'}
        `}
      >
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu}>
            <X size={24} />
          </button>
        </div>
        <div className="flex flex-col items-center gap-4 p-4">
          <button
            onClick={navigateToHome}
            className="w-full text-center py-2 border-b border-gray-300"
          >
            Home
          </button>
          <button
            onClick={handleNavigateLogin}
            className="w-full text-center py-2 border-b border-gray-300"
          >
            Entrar
          </button>
          <button
            onClick={handleNavigateSignUp}
            className="w-full text-center py-2"
          >
            Cadastrar
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
