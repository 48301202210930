import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MapPin, Calendar, Star, Heart, MessageCircle } from 'lucide-react';
import Header from '../components/HeaderComponent';
// TODO MOCKED DATA

const mockProfiles = [
  {
    id:1,
    name: "Ana Silva",
    age: 28,
    city: "São Paulo",
    height: "1.70m",
    weight: "58kg",
    hourlyRate: 300,
    description: "Sou uma acompanhante sofisticada e inteligente. Adoro conhecer pessoas interessantes e proporcionar momentos únicos em eventos sociais e corporativos. Tenho experiência em eventos de alto padrão e gosto de adaptar-me a diferentes ambientes.",
    skills: [
      "Eventos Corporativos",
      "Jantares Executivos", 
      "Viagens", 
      "Coquetel",
      "Teatro e Ópera"
    ],
    languages: ["Português", "Inglês", "Espanhol"],
    galleryImages: [
      "https://i.pinimg.com/736x/c9/70/df/c970dfaf7eebcc5ac9ac13cec59dd259.jpg",
      "https://i.pinimg.com/736x/c9/70/df/c970dfaf7eebcc5ac9ac13cec59dd259.jpg",
      "https://i.pinimg.com/736x/c9/70/df/c970dfaf7eebcc5ac9ac13cec59dd259.jpg",
      "https://i.pinimg.com/736x/c9/70/df/c970dfaf7eebcc5ac9ac13cec59dd259.jpg"
    ],
    reviews: [
      {
        name: "Carlos",
        rating: 5,
        text: "Profissional excepcional, extremamente elegante e inteligente."
      },
      {
        name: "Ricardo",
        rating: 4.5,
        text: "Superou todas as expectativas. Uma companhia incrível para meu evento corporativo."
      }
    ]
  },
  {
    id: 2,
    name: "Beatriz Lima",
    age: 20,
    city: "Belo Horizonte",
    hourlyRate: 400,
    description:
      "Uma acompanhante charmosa e descontraída, perfeita para eventos sociais e viagens. Amo conhecer novos lugares e culturas.",
    skills: ["Viagens", "Coquetel", "Teatro"],
    languages: ["Português", "Inglês"],
    galleryImages: [
      "https://cdn.goenhance.ai/user/2024/07/19/c0c1400b-abc2-4541-a849-a7e4f361d28d_0.jpg",
      "https://cdn.goenhance.ai/user/2024/07/19/c0c1400b-abc2-4541-a849-a7e4f361d28d_0.jpg",
      "https://cdn.goenhance.ai/user/2024/07/19/c0c1400b-abc2-4541-a849-a7e4f361d28d_0.jpg",
      "https://cdn.goenhance.ai/user/2024/07/19/c0c1400b-abc2-4541-a849-a7e4f361d28d_0.jpg",
    ],
    reviews: [
      { name: "João", rating: 4.9, text: "Uma ótima companhia para jantares executivos." },
      { name: "Pedro", rating: 4.9, text: "Elegante e atenciosa. Recomendo fortemente." },
    ],
  },
  {
    id: 3,
    name: "Julia Santos",
    age: 22,
    city: "Florianópolis",
    hourlyRate: 600,
    description:
      "Uma acompanhante refinada e confiante, com experiência em eventos de luxo e alto padrão.",
    skills: ["Eventos de Luxo", "Viagens", "Jantares Executivos"],
    languages: ["Português", "Francês"],
    galleryImages: [
      "https://s2-oglobo.glbimg.com/95Vk-SbCHPSmi7ggBr34knNEdyA=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_da025474c0c44edd99332dddb09cabe8/internal_photos/bs/2023/A/5/BkXhBWR0Cjf1pw9XDJkw/whatsapp-image-2023-11-23-at-18.52.27.jpeg",
      "https://s2-oglobo.glbimg.com/95Vk-SbCHPSmi7ggBr34knNEdyA=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_da025474c0c44edd99332dddb09cabe8/internal_photos/bs/2023/A/5/BkXhBWR0Cjf1pw9XDJkw/whatsapp-image-2023-11-23-at-18.52.27.jpeg",
      "https://s2-oglobo.glbimg.com/95Vk-SbCHPSmi7ggBr34knNEdyA=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_da025474c0c44edd99332dddb09cabe8/internal_photos/bs/2023/A/5/BkXhBWR0Cjf1pw9XDJkw/whatsapp-image-2023-11-23-at-18.52.27.jpeg",
      "https://s2-oglobo.glbimg.com/95Vk-SbCHPSmi7ggBr34knNEdyA=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_da025474c0c44edd99332dddb09cabe8/internal_photos/bs/2023/A/5/BkXhBWR0Cjf1pw9XDJkw/whatsapp-image-2023-11-23-at-18.52.27.jpeg",
    ],
    reviews: [
      { name: "Lucas", rating: 5, text: "Perfeita para eventos de luxo. Sofisticada e discreta." },
      { name: "Marcos", rating: 5, text: "Profissional extremamente qualificada." },
    ],
  },
];

const ProfilePage = () => {
  const location = useLocation();
  const [theme, setTheme] = useState('dark');
  const [profileData, setProfileData] = useState(null);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }

    const params = new URLSearchParams(location.search);
    const id = parseInt(params.get('id'), 10);
    const profile = mockProfiles.find((p) => p.id === id);
    setProfileData(profile);
  }, [location]);

  if (!profileData) {
    return (
      <div className={`min-h-screen flex items-center justify-center ${theme === 'dark' ? 'bg-zinc-900 text-zinc-100' : 'bg-gray-50 text-gray-900'}`}>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <div className="text-center">
          <h1 className="text-2xl font-bold">Perfil não encontrado</h1>
          <p className="mt-4">O ID fornecido não corresponde a nenhum perfil.</p>
        </div>
      </div>
    );
  }
  return (
    <div className={`
      min-h-screen flex items-center justify-center
      ${theme === 'dark' 
        ? 'bg-zinc-900 text-zinc-100' 
        : 'bg-gray-50 text-gray-900'}
    `}>
      {/* Header */}
      <Header theme={theme} toggleTheme={toggleTheme} />

      {/* Conteúdo principal com espaço para o header fixo */}
      <div className="pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Seção de Imagens */}
          <div>
            <div className="aspect-w-3 aspect-h-4 mb-4">
              <img 
                src={profileData.galleryImages[0]} 
                alt={profileData.name}
                className="w-full h-full object-cover rounded-lg shadow-lg" 
              />
            </div>
            <div className="grid grid-cols-4 gap-2">
              {profileData.galleryImages.map((img, index) => (
                <img 
                  key={index}
                  src={img} 
                  alt={`Galeria ${index + 1}`}
                  className="w-full h-24 object-cover rounded cursor-pointer hover:opacity-80"
                  onClick={() => setSelectedGalleryImage(img)}
                />
              ))}
            </div>
          </div>

          {/* Informações do Perfil */}
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className={`
                text-3xl font-bold
                ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
              `}>
                {profileData.name}
              </h2>
              <div className="flex items-center">
                <Star className="w-6 h-6 text-yellow-400 fill-current mr-2" />
                <span className={`
                  text-xl font-semibold
                  ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                `}>
                  4.9
                </span>
              </div>
            </div>

            <div className={`
              flex space-x-4 mb-4
              ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-600'}
            `}>
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-2" />
                <span>{profileData.city}</span>
              </div>
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                <span>{profileData.age} anos</span>
              </div>
            </div>

            <div className={`
              mb-4 text-base
              ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-800'}
            `}>
              {profileData.description}
            </div>

            <div className="mb-4">
              <h3 className={`
                text-xl font-semibold mb-2
                ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
              `}>
                Serviços e Habilidades
              </h3>
              <div className="flex flex-wrap gap-2">
                {profileData.skills.map((skill, index) => (
                  <span 
                    key={index}
                    className={`
                      px-3 py-1 rounded-full text-sm
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-zinc-300' 
                        : 'bg-gray-200 text-gray-700'}
                    `}
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <h3 className={`
                text-xl font-semibold mb-2
                ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
              `}>
                Idiomas
              </h3>
              <div className="flex space-x-2">
                {profileData.languages.map((lang, index) => (
                  <span 
                    key={index}
                    className={`
                      px-3 py-1 rounded-full text-sm
                      ${theme === 'dark' 
                        ? 'bg-zinc-700 text-zinc-300' 
                        : 'bg-gray-200 text-gray-700'}
                    `}
                  >
                    {lang}
                  </span>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className={`
                  text-xl font-semibold
                  ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
                `}>
                  Valor por Hora
                </h3>
                <span className={`
                  text-2xl font-bold
                  ${theme === 'dark' ? 'text-violet-400' : 'text-violet-600'}
                `}>
                  R$ {profileData.hourlyRate},00
                </span>
              </div>

              <div className={`mb-6
              rounded-lg overflow-hidden
              ${theme === 'dark' ? 'bg-zinc-800' : 'bg-white shadow-md'}
            `}>
              <div className="p-6">
                <h3 className={`
                  text-xl font-semibold mb-4
                  ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
                `}>
                  Tabela de Preços
                </h3>
                <table className="w-full">
                  <thead className={`
                    ${theme === 'dark' 
                      ? 'bg-zinc-700 text-zinc-200' 
                      : 'bg-violet-100 text-violet-800'}
                  `}>
                    <tr>
                      <th className="p-3 text-left">Duração</th>
                      <th className="p-3 text-right">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`
                      ${theme === 'dark' 
                        ? 'hover:bg-zinc-700' 
                        : 'hover:bg-violet-50'}
                    `}>
                      <td className="p-3">1 Hora</td>
                      <td className="p-3 text-right font-semibold text-green-500">R${profileData.hourlyRate}</td>
                    </tr>
                    <tr className={`
                      ${theme === 'dark' 
                        ? 'bg-zinc-700/50 hover:bg-zinc-700' 
                        : 'bg-violet-50 hover:bg-violet-100'}
                    `}>
                      <td className="p-3">2 Horas</td>
                      <td className="p-3 text-right font-semibold text-green-500">A combinar</td>
                    </tr>
                    <tr className={`
                      ${theme === 'dark' 
                        ? 'hover:bg-zinc-700' 
                        : 'hover:bg-violet-50'}
                    `}>
                      <td className="p-3">3 Horas</td>
                      <td className="p-3 text-right font-semibold text-green-500">A combinar</td>
                    </tr>
                    <tr className={`
                      ${theme === 'dark' 
                        ? 'bg-zinc-700/50 hover:bg-zinc-700' 
                        : 'bg-violet-50 hover:bg-violet-100'}
                    `}>
                      <td className="p-3">4 Horas</td>
                      <td className="p-3 text-right font-semibold text-green-500">A combinar</td>
                    </tr>
                    <tr className={`
                      ${theme === 'dark' 
                        ? 'hover:bg-zinc-700' 
                        : 'hover:bg-violet-50'}
                    `}>
                      <td className="p-3">Dia Inteiro</td>
                      <td className="p-3 text-right font-semibold text-green-500">A combinar</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

              {/* <div className="mb-4">
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  Tabela de Valores
                </h3>
                <table className="w-full border-collapse border border-gray-200 text-center">
                  <thead>
                    <tr className={`${theme === 'dark' ? 'bg-zinc-800 text-white' : 'bg-gray-100 text-gray-900'}`}>
                      <th className="border border-gray-300 py-2">Duração</th>
                      <th className="border border-gray-300 py-2">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`${theme === 'dark' ? 'bg-zinc-700 text-zinc-300' : 'bg-white text-gray-700'}`}>
                      <td className="border border-gray-300 py-2">1 Hora</td>
                      <td className="border border-gray-300 py-2">R$ {profileData.hourlyRate},00</td>
                    </tr>
                    <tr className={`${theme === 'dark' ? 'bg-zinc-800 text-zinc-300' : 'bg-gray-100 text-gray-700'}`}>
                      <td className="border border-gray-300 py-2">2 Horas</td>
                      <td className="border border-gray-300 py-2">A combinar</td>
                    </tr>
                    <tr className={`${theme === 'dark' ? 'bg-zinc-700 text-zinc-300' : 'bg-white text-gray-700'}`}>
                      <td className="border border-gray-300 py-2">4 Horas</td>
                      <td className="border border-gray-300 py-2">A combinar</td>
                    </tr>
                    <tr className={`${theme === 'dark' ? 'bg-zinc-800 text-zinc-300' : 'bg-gray-100 text-gray-700'}`}>
                      <td className="border border-gray-300 py-2">Dia Inteiro</td>
                      <td className="border border-gray-300 py-2">A combinar</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <div className="grid grid-cols-2 gap-4">
                <button className={`
                  flex items-center justify-center py-3 rounded-md
                  ${theme === 'dark' 
                    ? 'bg-violet-700 text-white hover:bg-violet-600' 
                    : 'bg-violet-600 text-white hover:bg-violet-700'}
                `}>
                  <MessageCircle className="mr-2" /> Enviar Mensagem
                </button>
                <button className={`
                  flex items-center justify-center py-3 rounded-md
                  ${theme === 'dark' 
                    ? 'bg-zinc-700 text-zinc-300 hover:bg-zinc-600' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
                `}>
                  <Heart className="mr-2" /> Favoritar
                </button>
              </div>
            </div>

            {/* Avaliações */}
            <div>
              <h3 className={`
                text-xl font-semibold mb-4
                ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
              `}>
                Avaliações
              </h3>
              {profileData.reviews.map((review, index) => (
                <div 
                  key={index}
                  className={`
                    mb-4 p-4 rounded-lg
                    ${theme === 'dark' 
                      ? 'bg-zinc-800 text-zinc-300' 
                      : 'bg-gray-100 text-gray-700'}
                  `}
                >
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold">{review.name}</span>
                    <div className="flex items-center">
                      <Star className="w-5 h-5 text-yellow-400 fill-current mr-1" />
                      <span>{review.rating}</span>
                    </div>
                  </div>
                  <p>{review.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
