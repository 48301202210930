import React, { useState, useEffect } from 'react';
import { User, Lock, Mail, Camera, Languages, DollarSign, Briefcase, MapPin, CreditCard } from 'lucide-react';
import Header from '../components/HeaderComponent';
import { useNavigate } from "react-router-dom";


const SignupPage = () => {
  const [theme, setTheme] = useState('dark');
  const [profileType, setProfileType] = useState('client');
  const paymentOptions = ["Pix", "Cartão de Crédito", "Dinheiro", "Transferência Bancária"];
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    city: '',
    state: '',
    hourlyRate: '',
    twoHoursRate: '',
    fourHoursRate: '',
    dayRate:'',
    height: '',
    weight: '',
    description: '',
    skills: [],
    languages: [],
    photos: [],
    paymentMethods: [],
  });
  const [skillInput, setSkillInput] = useState('');
  const navigate = useNavigate();
  const [languageInput, setLanguageInput] = useState('');

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'photos') {
      setFormData((prev) => ({
        ...prev,
        photos: Array.from(files),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSkillInput = (e) => {
    const value = e.target.value;
    if (value.includes(' ')) {
      const skill = value.trim();
      if (skill && !formData.skills.includes(skill)) {
        setFormData((prev) => ({ ...prev, skills: [...prev.skills, skill] }));
      }
      setSkillInput('');
    } else {
      setSkillInput(value);
    }
  };

  const removeSkill = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      skills: prev.skills.filter((skill) => skill !== skillToRemove),
    }));
  };

  const handleLanguageInput = (e) => {
    const value = e.target.value;
    if (value.includes(' ')) {
      const language = value.trim();
      if (language && !formData.languages.includes(language)) {
        setFormData((prev) => ({ ...prev, languages: [...prev.languages, language] }));
      }
      setLanguageInput('');
    } else {
      setLanguageInput(value);
    }
  };

  const removeLanguage = (languageToRemove) => {
    setFormData((prev) => ({
      ...prev,
      languages: prev.languages.filter((language) => language !== languageToRemove),
    }));
  };

  const handlePaymentMethodChange = (method) => {
    setFormData((prev) => ({
      ...prev,
      paymentMethods: prev.paymentMethods.includes(method)
        ? prev.paymentMethods.filter((m) => m !== method)
        : [...prev.paymentMethods, method],
    }));
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <div
      className={`
        min-h-screen flex items-center justify-center
        ${theme === 'dark' ? 'bg-zinc-900 text-zinc-100' : 'bg-gray-50 text-gray-900'}
      `}
    >
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div
        className={`
          w-full max-w-md p-8 space-y-6
          ${theme === 'dark' ? 'bg-zinc-800' : 'bg-white'}
          rounded-xl shadow-2xl mt-28 mb-4
        `}
      >
        <h2
          className={`
            text-center text-3xl font-bold
            ${theme === 'dark' ? 'text-white' : 'text-gray-900'}
          `}
        >
          Cadastro
        </h2>
        <div className="flex justify-center mb-4">
          <div className="flex gap-4">
            <button
              onClick={() => setProfileType('client')}
              className={`
                px-4 py-2 rounded-md
                ${profileType === 'client'
                  ? (theme === 'dark' ? 'bg-violet-700' : 'bg-violet-600')
                  : (theme === 'dark' ? 'bg-zinc-700' : 'bg-gray-200')}
                ${profileType === 'client' ? 'text-white' : 'text-gray-600'}
              `}
            >
              Contratante
            </button>
            <button
              onClick={() => setProfileType('companion')}
              className={`
                px-4 py-2 rounded-md
                ${profileType === 'companion'
                  ? (theme === 'dark' ? 'bg-violet-700' : 'bg-violet-600')
                  : (theme === 'dark' ? 'bg-zinc-700' : 'bg-gray-200')}
                ${profileType === 'companion' ? 'text-white' : 'text-gray-600'}
              `}
            >
              Acompanhante
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="relative">
            <User
              className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
              size={20}
            />
            <input
              type="text"
              name="name"
              placeholder="Nome Completo"
              className={`
                w-full p-3 pl-10 rounded-md
                ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
              `}
              onChange={handleInputChange}
            />
          </div>
          <div className="relative">
            <Mail
              className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
              size={20}
            />
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              className={`
                w-full p-3 pl-10 rounded-md
                ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
              `}
              onChange={handleInputChange}
            />
          </div>
          <div className="relative">
            <Lock
              className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
              size={20}
            />
            <input
              type="password"
              name="password"
              placeholder="Senha"
              className={`
                w-full p-3 pl-10 rounded-md
                ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
              `}
              onChange={handleInputChange}
            />
          </div>

          {profileType === 'companion' && (
            <>
              <div className="relative">
                <MapPin
                  className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                  size={20}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="Cidade"
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="relative">
                <input
                  type="text"
                  name="state"
                  placeholder="Estado"
                  className={`
                    w-full p-3 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="relative">
                <input
                  type="text"
                  name="description"
                  placeholder="Descrição"
                  className={`
                    w-full p-3 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="relative">
                <input
                  type="number"
                  name="height"
                  placeholder="Altura (ex: 1.70m)"
                  className={`
                    w-full p-3 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="relative">
                  <input
                  type="number"
                  name="weight"
                  placeholder="Peso (ex: 58kg)"
                  className={`
                    w-full p-3 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="relative">
                  <div className="absolute left-3 top-4">
                    <Briefcase
                      className={`${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                      size={20}
                    />
                  </div>
                <div>
                    <input
                      type="text"
                      placeholder="Serviços e Habilidades"
                      className={`
                        w-full p-3 pl-10 rounded-md
                        ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                      `}
                      value={skillInput}
                      onChange={handleSkillInput}
                    />
                    <div className="flex flex-wrap gap-2 mt-2">
                      {formData.skills.map((skill, index) => (
                        <span
                          key={index}
                          className="flex items-center px-3 py-1 rounded-full bg-violet-600 text-white text-sm"
                        >
                          {skill}
                          <button
                            className="ml-2 text-white hover:text-gray-200"
                            onClick={() => removeSkill(skill)}
                          >
                            &times;
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
              </div>
              
              <div className="relative">
                <div className="absolute left-3 top-4">
                  <Languages
                    className={`${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                    size={20}
                  />
                </div>
                <div>
                <input
                  type="text"
                  placeholder="Idiomas"
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  value={languageInput}
                  onChange={handleLanguageInput}
                />
                <div className="flex flex-wrap gap-2 mt-2">
                  {formData.languages.map((language, index) => (
                    <span
                      key={index}
                      className="flex items-center px-3 py-1 rounded-full bg-violet-600 text-white text-sm"
                    >
                      {language}
                      <button
                        className="ml-2 text-white hover:text-gray-200"
                        onClick={() => removeLanguage(language)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
                  </div>
                </div>
              </div>

              <div className="relative">
                <DollarSign
                  className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                  size={20}
                />
                <input
                  type="text"
                  name="hourlyRate"
                  placeholder={`Valor por hora (ou deixe "A combinar")`}
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      hourlyRate: e.target.value || 'A combinar',
                    }))
                  }
                />
              </div>
              
              <div className="relative">
                <DollarSign
                  className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                  size={20}
                />
                <input
                  type="text"
                  name="twoHoursRate"
                  placeholder={`Valor por 2 horas (ou deixe "A combinar")`}
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      twoHoursRate: e.target.value || 'A combinar',
                    }))
                  }
                />
              </div>    
              <div className="relative">
                <DollarSign
                  className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                  size={20}
                />
                <input
                  type="text"
                  name="dayRate"
                  placeholder={`Valor por dia (ou deixe "A combinar")`}
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dayRate: e.target.value || 'A combinar',
                    }))
                  }
                />
              </div>
              
              <div className="relative">
                <Camera
                  className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}
                  size={20}
                />
                <input
                  type="file"
                  multiple
                  name="photos"
                  className={`
                    w-full p-3 pl-10 rounded-md
                    ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                  `}
                  onChange={handleInputChange}
                />
                {formData.photos.length > 0 && (
                  <div className="mt-2 text-sm">
                    {formData.photos.length} foto(s) selecionada(s)
                  </div>
                )}
              </div>

              <div className="relative">
                <div  className={`
                      w-full p-3 rounded-md
                      ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-600' : 'bg-gray-100 text-gray-900 border-gray-300'}
                    `}>
                  <label className={`
                    flex items-center mb-2
                    ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}
                  `}>
                    <CreditCard className="mr-2" size={20} /> 
                    Meios de Pagamento
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {['Pix', 'Cartão', 'PayPal', 'Transferência'].map((method) => (
                      <button
                        key={method}
                        type="button"
                        onClick={() => {
                          if (formData.paymentMethods.includes(method)) {
                            setFormData((prev) => ({
                              ...prev,
                              paymentMethods: prev.paymentMethods.filter((item) => item !== method),
                            }));
                          } else {
                            setFormData((prev) => ({
                              ...prev,
                              paymentMethods: [...prev.paymentMethods, method],
                            }));
                          }
                        }}
                        className={`
                          px-3 py-1 rounded-full text-sm
                          ${formData.paymentMethods.includes(method)
                            ? (theme === 'dark' 
                              ? 'bg-violet-700 text-white' 
                              : 'bg-violet-600 text-white')
                            : (theme === 'dark' 
                              ? 'bg-zinc-700 text-zinc-400' 
                              : 'bg-gray-200 text-gray-600')}
                        `}
                      >
                        {method}
                      </button>
                    ))}
                  </div>
                  {/* <div className="flex flex-wrap gap-2 mt-2">
                    {formData.paymentMethods.map((method, index) => (
                      <span
                        key={index}
                        className="flex items-center px-3 py-1 rounded-full bg-violet-600 text-white text-sm"
                      >
                        {method}
                        <button
                          className="ml-2 text-white hover:text-gray-200"
                          onClick={() =>
                            setFormData((prev) => ({
                              ...prev,
                              paymentMethods: prev.paymentMethods.filter((item) => item !== method),
                            }))
                          }
                        >
                          &times;
                        </button>
                      </span>
                    ))}
                  </div> */}
                </div>
              </div>
      
            </>
          )}

          <button className={`
            w-full p-3 rounded-md mt-4
            ${theme === 'dark' 
              ? 'bg-violet-700 text-white hover:bg-violet-600' 
              : 'bg-violet-600 text-white hover:bg-violet-700'}
          `}>
            Cadastrar
          </button>
        </div>
        <div className="text-center">
          <span className={`
            ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-600'}
          `}>
            Já tem conta? 
            <button 
              onClick={() => navigate("/login")}
              className={`
                ml-2 font-bold
                ${theme === 'dark' ? 'text-violet-400' : 'text-violet-600'}
              `}
            >
              Entrar
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
